import { createSlice } from "@reduxjs/toolkit"
import {
  approveEdaatPayment,
  approveRejectOwnerShipDetails,
  approveRejectTaxExemption,
  cancelBooking,
  getAllBuyingPayments,
  getAllDeedRecords,
  getBuyingPaymentDetails,
  holdUnHoldPayment,
  uploadDeedDocuments,
  uploadDeliveryLetter,
  uploadPaymentContract,
  uploadPaymentReceipt,
  uploadRETTCertificate,
} from "store/actions/buyingPaymentsAction"

const initialState = {
  loading: true,
  isLoading: false,
  isGettingDetails: false,
  perPage: 10,
  totalCount: null,
  totalPages: null,
  currentPage: null,
  buyingPayments: [],
  buyingPaymentDetails: {},
}
const buyingPaymentsSlice = createSlice({
  name: "buying payment",
  initialState,
  reducers: {},
  extraReducers: builder => {
    // GET ALL
    builder
      .addCase(getAllBuyingPayments.pending, state => {
        state.loading = true
        state.buyingPayments = []
        state.totalCount = null
        state.totalPages = null
        state.currentPage = null
      })
      .addCase(getAllBuyingPayments.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data, totalCount, totalPages, currentPage } = action.payload
          state.buyingPayments = data
          state.totalCount = totalCount
          state.totalPages = totalPages
          state.currentPage = currentPage
        }
      })
      .addCase(getAllBuyingPayments.rejected, (state, action) => {
        state.loading = false
      })

    // GET ALL DEED
    builder
      .addCase(getAllDeedRecords.pending, state => {
        state.loading = true
        state.buyingPayments = []
        state.totalCount = null
        state.totalPages = null
        state.currentPage = null
      })
      .addCase(getAllDeedRecords.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data, totalCount, totalPages, currentPage } = action.payload
          state.buyingPayments = data
          state.totalCount = totalCount
          state.totalPages = totalPages
          state.currentPage = currentPage
        }
      })
      .addCase(getAllDeedRecords.rejected, (state, action) => {
        state.loading = false
      })

    // HOLD/UN HOLD DOWN PAYMENT
    builder
      .addCase(holdUnHoldPayment.pending, state => {
        state.isLoading = true
      })
      .addCase(holdUnHoldPayment.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { id, hold } = action.meta.arg

          // Update the specific payment based on the hold/unhold action
          state.buyingPayments = state.buyingPayments.map(item => {
            if (item._id === id) {
              return {
                ...item,
                paymentDetails: {
                  ...item.paymentDetails,
                  paymentHoldAt: hold ? new Date().toISOString() : null, // Set current UTC time if hold, null if unhold
                },
              }
            }
            return item
          })
        }
      })
      .addCase(holdUnHoldPayment.rejected, (state, action) => {
        state.isLoading = false
      })

    // HOLD/UN HOLD DOWN PAYMENT
    builder
      .addCase(cancelBooking.pending, state => {
        state.isLoading = true
      })
      .addCase(cancelBooking.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        const { id } = action.meta.arg

        if (statusCode === 200 && success) {
          state.buyingPayments = state.buyingPayments.map(payment =>
            payment._id === id
              ? { ...payment, status: 2, canCancelBooking: false }
              : payment
          )
        }
      })
      .addCase(cancelBooking.rejected, (state, action) => {
        state.isLoading = false
      })

    // UPLOAD CONTRACT
    builder
      .addCase(uploadPaymentContract.pending, state => {
        state.isLoading = true
      })
      .addCase(uploadPaymentContract.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        const { id } = action.meta.arg
        if (statusCode === 200 && success) {
          state.buyingPayments = state?.buyingPayments?.map(item => {
            if (item._id === id) {
              return {
                ...item,
                paymentContractDetails: {
                  ...item.paymentContractDetails,
                  uploadAt: new Date().toISOString(), // Set current UTC time
                },
              }
            }
            return item
          })
        }
      })
      .addCase(uploadPaymentContract.rejected, (state, action) => {
        state.isLoading = false
      })

    // UPLOAD DELIVERY LETTER
    builder
      .addCase(uploadDeliveryLetter.pending, state => {
        state.isLoading = true
      })
      .addCase(uploadDeliveryLetter.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        const { id } = action.meta.arg
        if (statusCode === 200 && success) {
          state.buyingPayments = state?.buyingPayments?.map(item => {
            if (item._id === id) {
              return {
                ...item,
                deliveryLetterDetails: {
                  ...item.deliveryLetterDetails,
                  uploadAt: new Date().toISOString(), // Set current UTC time
                },
              }
            }
            return item
          })
        }
      })
      .addCase(uploadDeliveryLetter.rejected, (state, action) => {
        state.isLoading = false
      })

    // UPLOAD DEED DOCS
    builder
      .addCase(uploadDeedDocuments.pending, state => {
        state.isLoading = true
      })
      .addCase(uploadDeedDocuments.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        const { id } = action.meta.arg
        if (statusCode === 200 && success) {
          state.buyingPayments = state?.buyingPayments?.map(item => {
            if (item._id === id) {
              return {
                ...item,
                deedDocumentDetails: {
                  ...item.deedDocumentDetails,
                  uploadAt: new Date().toISOString(), // Set current UTC time
                },
              }
            }
            return item
          })
        }
      })
      .addCase(uploadDeedDocuments.rejected, (state, action) => {
        state.isLoading = false
      })

    // GET DETAILS
    builder
      .addCase(getBuyingPaymentDetails.pending, state => {
        state.isGettingDetails = true
        state.buyingPaymentDetails = {}
      })
      .addCase(getBuyingPaymentDetails.fulfilled, (state, action) => {
        state.isGettingDetails = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data } = action.payload
          state.buyingPaymentDetails = data
        }
      })
      .addCase(getBuyingPaymentDetails.rejected, (state, action) => {
        state.isGettingDetails = false
      })

    // APPROVE/REJECT TAX EXEMPTION
    builder
      .addCase(approveRejectTaxExemption.pending, state => {
        state.isLoading = true
      })
      .addCase(approveRejectTaxExemption.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { id, approve } = action.meta.arg

          // Update the specific payment based on the hold/unhold action
          state.buyingPayments = state.buyingPayments.map(item => {
            if (item._id === id) {
              return {
                ...item,
                taxExemptionDetails: {
                  ...item.taxExemptionDetails,
                  documentApprovedAt: approve
                    ? new Date().toISOString()
                    : item.taxExemptionDetails.documentApprovedAt,
                  documentRejectedAt: !approve
                    ? new Date().toISOString()
                    : item.taxExemptionDetails.documentRejectedAt,
                },
              }
            }
            return item
          })
        }
      })
      .addCase(approveRejectTaxExemption.rejected, (state, action) => {
        state.isLoading = false
      })

    // APPROVE/REJECT OWNERSHIP REQUEST
    builder
      .addCase(approveRejectOwnerShipDetails.pending, state => {
        state.isLoading = true
      })
      .addCase(approveRejectOwnerShipDetails.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { id, approve } = action.meta.arg

          // Update the specific payment based on the hold/unhold action
          state.buyingPayments = state.buyingPayments.map(item => {
            if (item._id === id) {
              return {
                ...item,
                ownershipDocumentsDetails: {
                  ...item.ownershipDocumentsDetails,
                  approvedAt: approve
                    ? new Date().toISOString()
                    : item.ownershipDocumentsDetails.approvedAt,
                  rejectedAt: !approve
                    ? new Date().toISOString()
                    : item.ownershipDocumentsDetails.rejectedAt,
                },
              }
            }
            return item
          })
        }
      })
      .addCase(approveRejectOwnerShipDetails.rejected, (state, action) => {
        state.isLoading = false
      })

    // APPROVE EDAAT PAYMENT
    builder
      .addCase(approveEdaatPayment.pending, state => {
        state.isLoading = true
      })
      .addCase(approveEdaatPayment.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        const { payload, type } = action.meta.arg
        if (statusCode === 200 && success) {
          const { id } = payload
          if (type === 1) {
            // Update the item for type 1
            state.buyingPayments = state.buyingPayments.map(item => {
              if (item._id === id) {
                return {
                  ...item,
                  paymentDetails: {
                    ...item.paymentDetails,
                    downPaymentApprovedAt: new Date().toISOString(),
                  },
                }
              }
              return item
            })
          } else if (type === 2 || type === 3) {
            // Remove the item for type 2 or 5
            state.buyingPayments = state.buyingPayments.filter(
              item => item._id !== id
            )
          }
        }
      })
      .addCase(approveEdaatPayment.rejected, (state, action) => {
        state.isLoading = false
      })

    // UPLOAD RETT CERTIFICATE
    builder
      .addCase(uploadRETTCertificate.pending, state => {
        state.isLoading = true
      })
      .addCase(uploadRETTCertificate.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        const { id } = action.meta.arg
        if (statusCode === 200 && success) {
          state.buyingPayments = state?.buyingPayments?.map(item => {
            if (item._id === id) {
              return {
                ...item,
                RETTCertificate: {
                  ...item.RETTCertificate,
                  uploadAt: new Date().toISOString(), // Set current UTC time
                },
              }
            }
            return item
          })
        }
      })
      .addCase(uploadRETTCertificate.rejected, (state, action) => {
        state.isLoading = false
      })

    // UPLOAD PAYMENT RECEIPT
    builder
      .addCase(uploadPaymentReceipt.pending, state => {
        state.isLoading = true
      })
      .addCase(uploadPaymentReceipt.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        const { id } = action.meta.arg
        if (statusCode === 200 && success) {
          state.buyingPayments = state?.buyingPayments?.map(item => {
            if (item._id === id) {
              return {
                ...item,
                deedDocumentDetails: {
                  ...item.deedDocumentDetails,
                  warrantiesDoc: new Date().toISOString(), // Set current UTC time
                },
              }
            }
            return item
          })
        }
      })
      .addCase(uploadPaymentReceipt.rejected, (state, action) => {
        state.isLoading = false
      })
  },
})

export default buyingPaymentsSlice.reducer
