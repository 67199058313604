import { createSlice } from "@reduxjs/toolkit"
import {
  createProject,
  deleteProject,
  getAllProjects,
  getProjectDetails,
  getProjectsList,
  syncProjectsUnits,
  updateProject,
  updateProjectStatus,
  uploadProjectGallery,
  uploadProjectVideo,
} from "store/actions/projectsAction"

const initialState = {
  loading: true,
  isLoading: false,
  perPage: 10,
  totalCount: null,
  totalPages: null,
  currentPage: null,
  projects: [],
  projectsList: [],
  projectDetails: {},
}
const usersSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {},
  extraReducers: builder => {
    // GET ALL
    builder
      .addCase(getAllProjects.pending, state => {
        state.loading = true
      })
      .addCase(getAllProjects.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data, totalCount, totalPages, currentPage } = action.payload
          state.projects = data
          state.totalCount = totalCount
          state.totalPages = totalPages
          state.currentPage = currentPage
        }
      })
      .addCase(getAllProjects.rejected, (state, action) => {
        state.loading = false
      })

    // GET LIST
    builder
      .addCase(getProjectsList.pending, state => {})
      .addCase(getProjectsList.fulfilled, (state, action) => {
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data } = action.payload
          state.projectsList = data
        }
      })
      .addCase(getProjectsList.rejected, (state, action) => {})

    // GET DETAILS
    builder
      .addCase(getProjectDetails.pending, state => {
        state.loading = true
      })
      .addCase(getProjectDetails.fulfilled, (state, action) => {
        state.loading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { data } = action.payload
          state.projectDetails = data
        }
      })
      .addCase(getProjectDetails.rejected, (state, action) => {
        state.loading = false
      })

    // UPDATE STATUS
    builder
      .addCase(updateProjectStatus.pending, state => {
        state.isLoading = true
      })
      .addCase(updateProjectStatus.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success, data } = action.payload
        if (statusCode === 200 && success) {
          const { id, status } = action.meta.arg
          state.projects = state.projects.map(item =>
            item._id === id ? { ...item, status: data?.status } : item
          )
        }
      })
      .addCase(updateProjectStatus.rejected, (state, action) => {
        state.isLoading = false
      })

    // DELETE
    builder
      .addCase(deleteProject.pending, state => {
        state.isLoading = true
      })
      .addCase(deleteProject.fulfilled, (state, action) => {
        state.isLoading = false
        const { statusCode, success } = action.payload
        if (statusCode === 200 && success) {
          const { id } = action.meta.arg
          state.projects = state.projects.filter(item => item._id !== id)
          if (state.totalCount !== null) {
            state.totalCount -= 1
          }
        }
      })
      .addCase(deleteProject.rejected, (state, action) => {
        state.isLoading = false
      })

    // CREATE
    builder
      .addCase(createProject.pending, state => {
        state.isLoading = true
      })
      .addCase(createProject.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(createProject.rejected, (state, action) => {
        state.isLoading = false
      })

    // UPDATE
    builder
      .addCase(updateProject.pending, state => {
        state.isLoading = true
      })
      .addCase(updateProject.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(updateProject.rejected, (state, action) => {
        state.isLoading = false
      })

    // UPLOAD GALLERY IMAGES
    builder
      .addCase(uploadProjectGallery.pending, state => {
        state.isLoading = true
      })
      .addCase(uploadProjectGallery.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(uploadProjectGallery.rejected, (state, action) => {
        state.isLoading = false
      })

    // UPLOAD VIDEO
    builder
      .addCase(uploadProjectVideo.pending, state => {
        state.isLoading = true
      })
      .addCase(uploadProjectVideo.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(uploadProjectVideo.rejected, (state, action) => {
        state.isLoading = false
      })

    // SYNC PROJECTS AND UNITS
    builder
      .addCase(syncProjectsUnits.pending, state => {
        state.isLoading = true
      })
      .addCase(syncProjectsUnits.fulfilled, (state, action) => {
        state.isLoading = false
      })
      .addCase(syncProjectsUnits.rejected, (state, action) => {
        state.isLoading = false
      })
  },
})

export default usersSlice.reducer
