import React, { useEffect, useState } from "react"
import { Card, Col, Container, Row, Table } from "reactstrap"
import { useTranslation } from "react-i18next"
import Title from "components/Shared/Title"
import useFilters from "components/Shared/useFilters"
import { useLocation, useNavigate } from "react-router-dom"
import Filters from "components/Shared/Filters"
import {
  baseFilterOptions,
  salesByOptions,
  salesReportOptions,
} from "components/Common/constants"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import FormateDate from "components/Shared/FormateDate"
import Paginations from "components/Common/Pagination"
import { useDispatch, useSelector } from "react-redux"
import { getDashboardData, getSalesReport } from "store/actions/commonAction"
import Spinners from "components/Shared/Spinners"
import {
  DownloadCSV,
  DownloadXLSX,
  getDateRange,
  unitName,
} from "components/Common/Utils"
import { getProjectsList } from "store/actions/projectsAction"
import { getUnitsList } from "store/actions/unitsAction"
import { getCustomersList } from "store/actions/customersAction"
import { EXPORT_DATA } from "helpers/url_helper"
import { exportData } from "helpers/api_helper"

const SalesReports = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  document.title = t("Sales Reports | Takween")

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { search } = useLocation()
  const pageNumber = Number(new URLSearchParams(search).get("page")) || 1

  const {
    loading,
    isLoading,
    salesReport,
    currentPage,
    totalPages,
    totalCount,
  } = useSelector(state => state.Common)

  const { projectsList } = useSelector(state => state.Projects)
  const { unitsList } = useSelector(state => state.Units)
  const { customersList } = useSelector(state => state.Customers)

  const [
    filters,
    keyword,
    setKeyword,
    dateRange,
    setDateRange,
    flatpickrRef,
    selectedId,
    setSelectedId,
    itemIdToDelete,
    setItemIdToDelete,
    showDeleteModal,
    setShowDeleteModal,
    isApiCalled,
    setIsApiCalled,
    handleFilterChange,
    resetFilters,
  ] = useFilters({
    type: "buying",
    salesBy: "project",
    customer: [],
    project: [],
    unit: [],
  })

  const filterOptions = [
    {
      name: "type",
      type: "select",
      options: salesReportOptions.map(item => ({
        label: t(item.label),
        value: item.value,
      })),
      placeholder: t("Select Type"),
    },
    {
      name: "salesBy",
      type: "select",
      options: salesByOptions.map(item => ({
        label: t(item.label),
        value: item.value,
      })),
      placeholder: t("Select Sales By"),
    },
    {
      name: "project",
      type: "multiSelect",
      options: projectsList.map(item => ({
        label: language === "sa" ? item.nameAr : item.name,
        value: item._id,
      })),
      placeholder: t("Select Projects"),
    },
    {
      name: "unit",
      type: "multiSelect",
      options: unitsList.map(item => ({
        label:  unitName(item),
        value: item._id,
      })),
      placeholder: t("Select Units"),
    },
    {
      name: "customer",
      type: "multiSelect",
      options: customersList.map(item => ({
        label: `${item.name || ""} ${item.phone || ""}`,
        value: item._id,
      })),
      placeholder: t("Type Customers name"),
    },
  ]

  useEffect(() => {
    fetchSalesReports()
  }, [])

  const fetchSalesReports = page => {
    const { type, salesBy, customer, project, unit } = filters

    const updatedProjects = project?.map(item => item.value) || []
    const updatedUnits = unit?.map(item => item.value) || []
    const updatedCustomers = customer?.map(item => item.value) || []

    const payload = {
      type: type, // buying , renting
      salesBy: salesBy, // project,unit, user
      pageNo: page || pageNumber,
      perPage: 10,
      filter: {
        customer: updatedCustomers,
        project: updatedProjects,
        unit: updatedUnits,
        dateRange: getDateRange(dateRange),
      },
    }
    dispatch(getSalesReport(payload))
    setIsApiCalled(true)

    navigate(`/sales-reports?page=${page || pageNumber}`)
  }

  useEffect(() => {
    const fetchProjectsList = () => {
      const queryParams = "?status=-1"
      dispatch(getProjectsList(queryParams))
    }
    fetchProjectsList()
  }, [])

  useEffect(() => {
    fetchUnitsList()
  }, [filters.project])

  const fetchUnitsList = () => {
    const { project } = filters
    const updatedProjects = project?.map(item => item.value) || []

    const payload = {
      project: updatedProjects,
      statusType: [1, 2, 3, 4],
    }
    dispatch(getUnitsList(payload))
  }

  useEffect(() => {
    if (isApiCalled) {
      handleSearch()
    }
  }, [filters])

  useEffect(() => {
    if (isApiCalled) {
      if (dateRange.min && dateRange.max) handleSearch()
    }
  }, [dateRange])

  const handleSearch = () => {
    fetchSalesReports(1)
  }

  const onPageChange = page => {
    fetchSalesReports(page)
  }

  const commonColumns = [
    { header: t("Total Sold Count"), accessor: "totalSoldCount" },
    { header: t("Total Sold Amount"), accessor: "totalSoldAmount" },
    { header: t("Total In Progress Count"), accessor: "totalInProgressCount" },
    {
      header: t("Total In Progress Amount"),
      accessor: "totalInProgressAmount",
    },
    { header: t("Total Cancelled Count"), accessor: "totalCancelledCount" },
    { header: t("Total Cancelled Amount"), accessor: "totalCancelledAmount" },
  ]

  const columns = [
    ...(filters.salesBy === "project"
      ? [
          { header: "#", accessor: "serialNo" },
          {
            header: t("Project Name"),
            accessor: language === "sa" ? "projectNameAr" : "projectName",
          },
        ]
      : filters.salesBy === "unit"
      ? [
          { header: "#", accessor: "serialNo" },
          {
            header: t("Unit Name"),
            accessor: language === "sa" ? "unitNameAr" : "unitName",
          },
        ]
      : filters.salesBy === "user"
      ? [
          { header: "#", accessor: "serialNo" },
          { header: t("User Name"), accessor: "userName" },
        ]
      : []),
    ...commonColumns,
  ]

  const renderCell = (item, accessor, index) => {
    if (accessor === "serialNo") {
      return index + 1
    }
    if (
      accessor === "totalSoldAmount" ||
      accessor === "totalInProgressAmount" ||
      accessor === "totalCancelledAmount"
    ) {
      return `${item[accessor].toFixed(2)} ${t("SAR")}`
    }
    return item[accessor]
  }

  const onInputChange = value => {
    const payload = {
      keyword: value,
    }
    dispatch(getCustomersList(payload))
  }

  const getExportFilters = () => {
    const { type, salesBy, customer, project, unit } = filters

    const updatedProjects = project?.map(item => item.value) || []
    const updatedUnits = unit?.map(item => item.value) || []
    const updatedCustomers = customer?.map(item => item.value) || []

    return {
      type: type,
      salesBy: salesBy,
      filter: {
        customer: updatedCustomers,
        project: updatedProjects,
        unit: updatedUnits,
        dateRange: getDateRange(dateRange),
      },
    }
  }

  const handleDownloadCSV = async () => {
    const payload = {
      type: "SalesReport",
      exportType: "csv",
      filter: getExportFilters(),
    }

    const response = await exportData(EXPORT_DATA, payload)
    if (response) {
      const csvData = response
      DownloadCSV(csvData, t("Sales Reports"))
    }
  }

  const handleDownloadXLSX = async () => {
    const payload = {
      type: "SalesReport",
      exportType: "xlsx",
      filter: getExportFilters(),
    }

    const response = await exportData(EXPORT_DATA, payload)
    if (response) {
      const csvData = response
      DownloadXLSX(csvData, t("Sales Reports"))
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <Title
                  title={t("Sales Reports")}
                  isExport={true}
                  handleDownloadCSV={handleDownloadCSV}
                  handleDownloadXLSX={handleDownloadXLSX}
                />
                <Filters
                  isGlobalFilter={false}
                  filters={filterOptions}
                  selectedFilters={filters}
                  handleFilterChange={handleFilterChange}
                  handleReset={() => resetFilters()}
                  isRangeDate={true}
                  flatpickrRef={flatpickrRef}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  isResetButton={true}
                  colSize={3}
                  onInputChange={onInputChange}
                />
                {loading ? (
                  <div className="mt-5">
                    <Spinners />
                  </div>
                ) : salesReport?.length === 0 ? (
                  <NoDataAvailable />
                ) : (
                  <div className="table-responsive">
                    <Table className="mb-0 full_th">
                      <thead>
                        <tr>
                          {columns.map(column => (
                            <th key={column.accessor}>{column.header}</th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {salesReport?.map((payment, index) => (
                          <tr key={payment._id}>
                            {columns.map(column => (
                              <td key={column.accessor}>
                                {renderCell(payment, column.accessor, index)}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                )}
              </Card>
            </Col>
            {!loading && salesReport?.length > 0 && (
              <Paginations
                currentPage={currentPage}
                totalPages={totalPages}
                totalCount={totalCount}
                onPageChange={onPageChange}
              />
            )}{" "}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default SalesReports
