import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useNavigate, useSearchParams } from "react-router-dom"
import TopTitle from "components/Shared/TopTitle"
import { Field, Form, Formik } from "formik"
import CustomLabel from "components/Shared/CustomLabel"
import CustomField from "components/Shared/CustomField"
import FormButtons from "components/Shared/FormButtons"
import { handle_4_3_FileUpload } from "components/Common/Utils"
import FileUpload from "components/Shared/FileUpload"
import CustomErrorMessage from "components/Shared/CustomErrorMessage"
import { createProjectSchema } from "components/Common/validation"
import {
  projectInterfaceOptions,
  projectPurposeOptions,
  projectStatusOptions,
} from "components/Common/constants"
import { createProject } from "store/actions/projectsAction"
import { showAlert } from "store/reducers/alertReducer"
import { useDispatch, useSelector } from "react-redux"
import { getCitiesList } from "store/actions/citiesAction"
import BackButton from "components/Shared/BackButton"
import { projectUploadMaxSize } from "components/Common/FileUploadSizeUtils"

const initialValues = {
  name: "",
  nameAr: "",
  cityId: "",
  description: "",
  descriptionAr: "",
  address: "",
  addressAr: "",
  latitude: "",
  longitude: "",
  numberOfFloor: "",
  interface: "",
  purpose: "",
  statusType: "",
  image: "",
}

const CreateProject = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("Create Project | Takween")

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { isLoading } = useSelector(state => state.Projects)
  const { citiesList } = useSelector(state => state.Cities)

  const [formValues, setFormValues] = useState(initialValues)

  useEffect(() => {
    const fetchCity = () => {
      dispatch(getCitiesList())
    }
    fetchCity()
  }, [])

  const handleOnSubmit = values => {
    const {
      name,
      nameAr,
      description,
      descriptionAr,
      address,
      addressAr,
      latitude,
      longitude,
      numberOfFloor,
      interface: interfaceField,
      purpose,
      statusType,
      image,
      cityId,
    } = values

    let formData = new FormData()
    formData.append("name", name)
    formData.append("nameAr", nameAr)
    formData.append("description", description)
    formData.append("descriptionAr", descriptionAr)
    formData.append("address", address)
    formData.append("addressAr", addressAr)
    formData.append("latitude", latitude)
    formData.append("longitude", longitude)
    formData.append("generalDetails[purpose]", purpose)
    formData.append("generalDetails[interface]", interfaceField)
    formData.append("generalDetails[totalNumberOfFloors]", numberOfFloor)
    formData.append("image", image)
    formData.append("statusType", 2) // STATIC (AVAILABLE FOR SALE)
    formData.append("cityId", cityId)

    dispatch(createProject(formData))
      .then(action => {
        const { statusCode, success, message, data } = action?.payload
        if (statusCode === 200 && success) {
          const { _id } = data
          dispatch(showAlert({ text: message, type: "success" }))
          navigate(`/projects/update?id=${_id}`)
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  return (
    <React.Fragment>
      <div className="page-content create_project_page">
        <Container fluid>
          <TopTitle
            title={t("Project")}
            subTitle={t("Feel the details for create a project")}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <h2 className="fs_18_700 g_start">
                    <BackButton />
                    {t("Create Project")}
                  </h2>
                  <Formik
                    initialValues={formValues}
                    validationSchema={createProjectSchema}
                    onSubmit={handleOnSubmit}
                  >
                    {({ values, errors, touched, setFieldValue }) => (
                      <Form>
                        <Row>
                          <Col lg={6}>
                            <div className="form_field">
                              <CustomLabel
                                htmlFor="name"
                                label={t("Name(English)")}
                                isRequired={true}
                              />
                              <CustomField
                                name="name"
                                type="text"
                                placeholder={t("Name(English)")}
                                touched={touched}
                                errors={errors}
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="form_field">
                              <CustomLabel
                                htmlFor="nameAr"
                                label={t("Name(Arabic)")}
                                isRequired={true}
                              />
                              <CustomField
                                name="nameAr"
                                type="text"
                                placeholder={t("Name(Arabic)")}
                                touched={touched}
                                errors={errors}
                                isArabic={true}
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="form_field">
                              <CustomLabel
                                htmlFor="numberOfFloor"
                                label={t("Number Of Floor")}
                                isRequired={true}
                              />
                              <CustomField
                                name="numberOfFloor"
                                type="text"
                                placeholder={t("Number Of Floor")}
                                touched={touched}
                                errors={errors}
                              />
                            </div>
                          </Col>

                          <Col lg={6}>
                            <div className="form_field">
                              <CustomLabel
                                htmlFor="interface"
                                label={t("Interface")}
                                isRequired={true}
                              />
                              <div className="select_wrapper">
                                <Field
                                  as="select"
                                  name="interface"
                                  className={`form-control b_r_12 ${
                                    errors.interface && touched.interface
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  onChange={e => {
                                    const selectedValue = e.target.value
                                    setFieldValue("interface", selectedValue)
                                  }}
                                >
                                  <option hidden>{t("Interface")}</option>
                                  {projectInterfaceOptions?.map(item => (
                                    <option key={item.value} value={item.value}>
                                      {t(item.label)}
                                    </option>
                                  ))}
                                </Field>
                              </div>
                              <CustomErrorMessage name={"interface"} />
                            </div>
                          </Col>

                          {/* <Col lg={6}>
                            <div className="form_field">
                              <CustomLabel
                                htmlFor="statusType"
                                label={t("Project Status")}
                                isRequired={true}
                              />
                              <div className="select_wrapper">
                                <Field
                                  as="select"
                                  name="statusType"
                                  className={`form-control b_r_12 ${
                                    errors.statusType && touched.statusType
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  onChange={e => {
                                    const selectedValue = e.target.value
                                    setFieldValue("statusType", selectedValue)
                                  }}
                                >
                                  <option hidden>{t("Project Status")}</option>
                                  {projectStatusOptions?.map(item => (
                                    <option key={item.value} value={item.value}>
                                      {t(item.label)}
                                    </option>
                                  ))}
                                </Field>
                              </div>
                              <CustomErrorMessage name={"statusType"} />
                            </div>
                          </Col> */}

                          <Col lg={6}>
                            <div className="form_field">
                              <CustomLabel
                                htmlFor="description"
                                label={t("General Details(English)")}
                                isRequired={true}
                              />
                              <Field
                                as="textarea"
                                id="description"
                                name="description"
                                rows={5}
                                placeholder={t("General Details(English)")}
                                className={`form-control b_r_12 ${
                                  errors.description && touched.description
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              <CustomErrorMessage name={"description"} />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="form_field">
                              <CustomLabel
                                htmlFor="descriptionAr"
                                label={t("General Details(Arabic)")}
                                isRequired={true}
                              />
                              <Field
                                as="textarea"
                                id="descriptionAr"
                                name="descriptionAr"
                                rows={5}
                                placeholder={t("General Details(Arabic)")}
                                className={`form-control rtl b_r_12 ${
                                  errors.descriptionAr && touched.descriptionAr
                                    ? "is-invalid"
                                    : ""
                                }`}
                              />
                              <CustomErrorMessage name={"descriptionAr"} />
                            </div>
                          </Col>

                          <Col lg={6}>
                            <div className="form_field">
                              <CustomLabel
                                htmlFor="purpose"
                                label={t("Purpose")}
                                isRequired={true}
                              />
                              <div className="select_wrapper">
                                <Field
                                  as="select"
                                  name="purpose"
                                  className={`form-control b_r_12 ${
                                    errors.purpose && touched.purpose
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  onChange={e => {
                                    const selectedValue = e.target.value
                                    setFieldValue("purpose", selectedValue)
                                  }}
                                >
                                  <option hidden>{t("Purpose")}</option>
                                  {projectPurposeOptions?.map(item => (
                                    <option key={item.value} value={item.value}>
                                      {t(item.label)}
                                    </option>
                                  ))}
                                </Field>
                              </div>
                              <CustomErrorMessage name={"purpose"} />
                            </div>
                          </Col>

                          <Col lg={6}>
                            <div className="form_field">
                              <CustomLabel
                                htmlFor="cityId"
                                label={t("Select City")}
                                isRequired={true}
                              />
                              <div className="select_wrapper">
                                <Field
                                  as="select"
                                  name="cityId"
                                  className={`form-control b_r_12 ${
                                    errors.cityId && touched.cityId
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  onChange={e => {
                                    const selectedValue = e.target.value
                                    setFieldValue("cityId", selectedValue)
                                  }}
                                >
                                  <option hidden>{t("Select City")}</option>
                                  {citiesList?.map(item => (
                                    <option key={item._id} value={item._id}>
                                      {item.name}
                                    </option>
                                  ))}
                                </Field>
                              </div>
                              <CustomErrorMessage name={"cityId"} />
                            </div>
                          </Col>

                          <Col lg={6}>
                            <div className="form_field">
                              <CustomLabel
                                htmlFor="address"
                                label={t("Address(English)")}
                                isRequired={true}
                              />
                              <CustomField
                                name="address"
                                type="text"
                                placeholder={t("Address(English)")}
                                touched={touched}
                                errors={errors}
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="form_field">
                              <CustomLabel
                                htmlFor="addressAr"
                                label={t("Address(Arabic)")}
                                isRequired={true}
                              />
                              <CustomField
                                name="addressAr"
                                type="text"
                                isArabic={true}
                                placeholder={t("Address(Arabic)")}
                                touched={touched}
                                errors={errors}
                              />
                            </div>
                          </Col>

                          <Col lg={6}>
                            <div className="form_field">
                              <CustomLabel
                                htmlFor="latitude"
                                label={t("Latitude")}
                                isRequired={true}
                              />
                              <CustomField
                                name="latitude"
                                type="text"
                                placeholder={t("Latitude")}
                                touched={touched}
                                errors={errors}
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="form_field">
                              <CustomLabel
                                htmlFor="longitude"
                                label={t("Longitude")}
                                isRequired={true}
                              />
                              <CustomField
                                name="longitude"
                                type="text"
                                placeholder={t("Longitude")}
                                touched={touched}
                                errors={errors}
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="form_field">
                              <CustomLabel
                                htmlFor="image"
                                label={t("Upload Feature Image")}
                                isRequired={true}
                              />
                              <FileUpload
                                name="image"
                                value={values.image}
                                touched={touched.image}
                                error={errors.image}
                                labelText={t("Upload Feature Image")}
                                setFieldValue={setFieldValue}
                                handleFileUpload={event =>
                                  handle_4_3_FileUpload(
                                    event,
                                    setFieldValue,
                                    "image",
                                    true, // Enable size validation
                                    projectUploadMaxSize
                                  )
                                }
                              />
                              <CustomErrorMessage name={"image"} />
                              <p className="fs_14_500 opacity-75">
                                {t(
                                  `Recommended Image Size: Rectangle (4:3 ratio, e.g., 800*600 px). Max size: {{maxSize}} KB`,
                                  { maxSize: projectUploadMaxSize }
                                )}
                              </p>
                            </div>
                          </Col>
                          <Col lg={12}>
                            <FormButtons
                              buttonText={t("Create")}
                              isLoading={isLoading}
                            />
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CreateProject
