import { ascDescOptions, paymentStatus } from "components/Common/constants"
import {
  fileAccept,
  getDateRange,
  handleFileUpload,
  unitName,
} from "components/Common/Utils"
import Filters from "components/Shared/Filters"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import Spinners from "components/Shared/Spinners"
import Title from "components/Shared/Title"
import useFilters from "components/Shared/useFilters"
import React, { useEffect, useState, useTransition } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { Card, Col, Container, Modal, Row, Table } from "reactstrap"
import {
  getAllBuyingPayments,
  getBuyingPaymentDetails,
  uploadRETTCertificate,
} from "store/actions/buyingPaymentsAction"
import { getUnitsList } from "store/actions/unitsAction"
import ViewIcon from "assets/images/icons/view.svg"
import Paginations from "components/Common/Pagination"
import IsLoading from "components/Shared/IsLoading"
import DetailsInnerDiv from "components/Shared/DetailsInnerDiv"
import FormateDate from "components/Shared/FormateDate"
import { Form, Formik } from "formik"
import CustomLabel from "components/Shared/CustomLabel"
import FileUpload from "components/Shared/FileUpload"
import CustomErrorMessage from "components/Shared/CustomErrorMessage"
import { RETTCertificateUploadSchema } from "components/Common/validation"
import { showAlert } from "store/reducers/alertReducer"

const RETTCertificate = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("RETT Certificate Upload | Takween")

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { search } = useLocation()
  const pageNumber = Number(new URLSearchParams(search).get("page")) || 1

  const {
    loading,
    isLoading,
    isGettingDetails,
    buyingPayments,
    buyingPaymentDetails,
    totalCount,
    totalPages,
    currentPage,
    perPage,
  } = useSelector(state => state.BuyingPayments)

  const { unitsList } = useSelector(state => state.Units)

  const [
    filters,
    keyword,
    setKeyword,
    dateRange,
    setDateRange,
    flatpickrRef,
    selectedId,
    setSelectedId,
    itemIdToDelete,
    setItemIdToDelete,
    showDeleteModal,
    setShowDeleteModal,
    isApiCalled,
    setIsApiCalled,
    handleFilterChange,
    resetFilters,
  ] = useFilters({
    orderBy: 2,
    status: 1,
  })

  const [modal, setModal] = useState(false)
  const [modalType, setModalType] = useState(null)

  useEffect(() => {
    fetchAllBuyingPayments()
    fetchUnitsList()
  }, [])

  const fetchAllBuyingPayments = page => {
    const { orderBy, unit, status } = filters
    const updatedUnits = unit?.map(item => item.value) || []

    const payload = {
      perPage,
      pageNo: page || pageNumber,
      orderBy,
      status: Number(status),
      unit: updatedUnits,
      customer: [], // STATIC
      paymentType: [1, 2, 3],
      keyword,
      dateRange: getDateRange(dateRange),
    }
    dispatch(getAllBuyingPayments(payload))
    setIsApiCalled(true)
    navigate(
      keyword ? `/rett-certificate?keyword=${keyword}` : `/rett-certificate`
    )
  }

  const fetchUnitsList = () => {
    const payload = {
      project: [],
      statusType: [1, 2, 3, 4],
    }
    dispatch(getUnitsList(payload))
  }

  useEffect(() => {
    modal && modalType === "VIEW_DETAILS" && fetchPaymentDetails()
  }, [modal, modalType])

  const fetchPaymentDetails = () => {
    const payload = {
      id: selectedId,
    }
    dispatch(getBuyingPaymentDetails(payload))
  }

  const additionOptions = [
    {
      name: "status",
      type: "select",
      options: paymentStatus.map(item => ({
        label: t(item.label),
        value: item.value,
      })),
      placeholder: t("Status"),
    },
    {
      name: "unit",
      type: "multiSelect",
      options: unitsList.map(item => ({
        label: unitName(item),
        value: item._id,
      })),
      placeholder: t("Select Units"),
    },
  ]

  const filterOptions = [...ascDescOptions, ...additionOptions]

  useEffect(() => {
    if (isApiCalled) {
      handleSearch()
    }
  }, [keyword, filters])

  useEffect(() => {
    if (isApiCalled) {
      if (dateRange.min && dateRange.max) handleSearch()
    }
  }, [dateRange])

  const handleSearch = () => {
    fetchAllBuyingPayments(1)
  }

  const onPageChange = page => {
    fetchAllBuyingPayments(page)
  }

  const toggle = () => {
    modal && setModalType(null)
    setModal(!modal)
  }

  const handleRETTCertificateUpload = values => {
    const { file } = values

    let data = new FormData()
    data.append("id", selectedId)
    data.append("file", file)

    dispatch(uploadRETTCertificate({ data, id: selectedId }))
      .then(action => {
        const { statusCode, success, message } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
          setSelectedId(null)
          toggle()
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card>
                <Title title={t("RETT Certificate")} />
                <div className="mt-2"></div>
                <Filters
                  isGlobalFilter={true}
                  SearchPlaceholder={t("Search")}
                  keyword={keyword}
                  setKeyword={setKeyword}
                  filters={filterOptions}
                  selectedFilters={filters}
                  handleFilterChange={handleFilterChange}
                  handleReset={() => resetFilters()}
                  isRangeDate={true}
                  flatpickrRef={flatpickrRef}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  isResetButton={true}
                  colSize={4}
                />
                {loading ? (
                  <Spinners />
                ) : buyingPayments.length === 0 ? (
                  <NoDataAvailable />
                ) : (
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead className="full_th">
                        <tr>
                          <th>{t("#")}</th>
                          <th>{t("Name")}</th>
                          {/* <th>{t("Last Name")}</th> */}
                          <th>{t("Unit Name")}</th>
                          <th>{t("Booking Id")}</th>
                          <th>{t("Status")}</th>
                          <th>{t("Action")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {buyingPayments.map((item, index) => (
                          <tr key={item._id}>
                            <th scope="row">
                              {(currentPage - 1) * perPage + index + 1}
                            </th>
                            <td>{item?.userData?.name}</td>
                            {/* <td>{item?.buyerDetails?.lastName}</td> */}
                            <td>
                              {language === "sa"
                                ? item?.unitData?.nameAr
                                : item?.unitData?.name || "-"}
                            </td>
                            <td>{item.bookingNo || "-"}</td>
                            <td>
                              {!item?.RETTCertificate?.uploadAt ? (
                                <p className="sold_status status_tag">
                                  {t("Pending")}
                                </p>
                              ) : item?.RETTCertificate?.uploadAt ? (
                                <p className="rended_status status_tag">
                                  {t("Sent")}
                                </p>
                              ) : (
                                ""
                              )}
                            </td>
                            <td>
                              <div className="d-flex gap-3">

                              <img
                                src={ViewIcon}
                                alt="Trash Icon"
                                width={24}
                                height={24}
                                className="cursor-pointer"
                                onClick={() => {
                                  setSelectedId(item._id)
                                  setModalType("VIEW_DETAILS")
                                  toggle()
                                }}
                              />
                              {!item?.RETTCertificate?.uploadAt && item?.status !== 2 &&(
                                <button
                                  className="upload_btn"
                                  onClick={() => {
                                    toggle()
                                    setModalType("UPLOAD_CONTRACT")
                                    setSelectedId(item._id)
                                  }}
                                  >
                                  {t("Upload")}
                                </button>
                              )}

                              {item?.status === 2 && (
                                <p className="reject_status status_tag fit_content opacity-75">
                                  {t("Booking Cancelled")}
                                </p>
                              )}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                )}
              </Card>
            </Col>
            {!loading && buyingPayments.length > 0 && (
              <Paginations
                currentPage={currentPage}
                totalPages={totalPages}
                totalCount={totalCount}
                onPageChange={onPageChange}
              />
            )}
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        centered
        className={`g_modal ${
          modalType === "VIEW_DETAILS" ? "w_850" : "w_600"
        }`}
      >
        <h2 className="modal_title">
          {modalType === "VIEW_DETAILS"
            ? t("RETT Certificate Details")
            : t("Upload RETT Certificate")}
        </h2>
        <button type="button" onClick={toggle} className="modal_close_btn">
          <i className="mdi mdi-close"></i>
        </button>
        <div>
          {modalType === "VIEW_DETAILS" &&
            (isGettingDetails ? (
              <IsLoading />
            ) : (
              <Row>
                <Col md={4}>
                  <DetailsInnerDiv
                    label={t("Name")}
                    value={buyingPaymentDetails?.userId?.name || "-"}
                  />
                </Col>
                <Col md={4}>
                  <DetailsInnerDiv
                    label={t("Phone Number")}
                    value={buyingPaymentDetails?.userId?.phone || "-"}
                  />
                </Col>
                <Col md={4}>
                  <DetailsInnerDiv
                    label={t("Email")}
                    value={buyingPaymentDetails?.buyerDetails?.email || "-"}
                  />
                </Col>
                <Col md={4}>
                  <DetailsInnerDiv
                    label={t("Unit Name")}
                    value={
                      language === "sa"
                        ? buyingPaymentDetails?.unitId?.nameAr
                        : buyingPaymentDetails?.unitId?.name || "-"
                    }
                  />
                </Col>
                <Col md={4}>
                  <DetailsInnerDiv
                    label={t("Booking Number")}
                    value={buyingPaymentDetails?.bookingNo || "-"}
                  />
                </Col>

                <Col md={4}>
                  <DetailsInnerDiv
                    label={t("Status")}
                    value={
                      !buyingPaymentDetails?.RETTCertificate?.uploadAt
                        ? t("Pending")
                        : buyingPaymentDetails?.RETTCertificate?.uploadAt
                        ? t("Sent")
                        : "-"
                    }
                    valueClass={
                      !buyingPaymentDetails?.RETTCertificate?.uploadAt
                        ? "pending_color"
                        : buyingPaymentDetails?.RETTCertificate?.uploadAt
                        ? "active_color"
                        : "-"
                    }
                  />
                </Col>
                <Col md={4}>
                  <DetailsInnerDiv
                    label={t("Customer ID")}
                    value={
                      buyingPaymentDetails?.buyerDetails?.nationalId || "-"
                    }
                  />
                </Col>

                <Col md={4}>
                  <DetailsInnerDiv
                    label={t("Deed Number")}
                    value={
                      buyingPaymentDetails?.unitId?.deedDetails?.deedNumber ||
                      "-"
                    }
                  />
                </Col>

                {buyingPaymentDetails?.unitId?.deedDetails?.document && (
                  <Col md={4}>
                    <DetailsInnerDiv
                      label={t("Original Deed Doc")}
                      value={t("View")}
                      isPdfIcon={true}
                      isLink={true}
                      redirectLink={
                        buyingPaymentDetails?.unitId?.deedDetails?.document
                      }
                    />
                  </Col>
                )}

                <Col md={4}>
                  <DetailsInnerDiv
                    label={t("Tax Exemption Attachment")}
                    value={t("Tax Exemption Document")}
                    isPdfIcon={true}
                    isLink={true}
                    redirectLink={
                      buyingPaymentDetails?.taxExemptionDetails?.attachment
                    }
                  />
                </Col>

                {buyingPaymentDetails?.RETTCertificate?.attachment && (
                  <Col md={4}>
                    <DetailsInnerDiv
                      label={t("RETT Certificate")}
                      value={t("View")}
                      isPdfIcon={true}
                      isLink={true}
                      redirectLink={
                        buyingPaymentDetails?.RETTCertificate?.attachment
                      }
                    />
                  </Col>
                )}

                {buyingPaymentDetails?.RETTCertificate?.uploadAt && (
                  <Col md={4}>
                    <DetailsInnerDiv
                      label={t("RETT Certificate Uploaded At")}
                      value={FormateDate(
                        buyingPaymentDetails?.RETTCertificate?.uploadAt
                      )}
                    />
                  </Col>
                )}
                <Col md={4}>
                  <DetailsInnerDiv
                    label={t("Created At")}
                    value={FormateDate(buyingPaymentDetails?.createdAt)}
                  />
                </Col>
                <Col md={4}>
                  <DetailsInnerDiv
                    label={t("Updated At")}
                    value={FormateDate(buyingPaymentDetails?.updatedAt)}
                  />
                </Col>
              </Row>
            ))}

          {modalType === "UPLOAD_CONTRACT" && (
            <Row>
              <Col lg={12}>
                <Formik
                  initialValues={{
                    file: "",
                  }}
                  validationSchema={RETTCertificateUploadSchema}
                  onSubmit={handleRETTCertificateUpload}
                >
                  {({ values, errors, touched, setFieldValue }) => (
                    <Form>
                      <Row>
                        <Col lg={12}>
                          <div className="form_field mb-0">
                            <CustomLabel
                              htmlFor="file"
                              label={t("Upload RETT Certificate")}
                              isRequired={true}
                            />
                            <FileUpload
                              name="file"
                              value={values.file}
                              touched={touched.file}
                              error={errors.file}
                              accept={fileAccept}
                              customClasses="h_72"
                              labelText={t("Upload File")}
                              setFieldValue={setFieldValue}
                              showNameOnly={true}
                              handleFileUpload={event =>
                                handleFileUpload(event, setFieldValue, "file")
                              }
                            />
                            <CustomErrorMessage name={"file"} />
                          </div>
                        </Col>

                        <Col lg={12}>
                          <button
                            className="save_btn mt-4 ms-auto d-block"
                            type="submit"
                            disabled={isLoading}
                          >
                            {t("Upload")}
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              </Col>
            </Row>
          )}
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default RETTCertificate
