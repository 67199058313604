import React, { useEffect, useState } from "react"
import { Col, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import { Field, FieldArray, Form, Formik } from "formik"
import CustomLabel from "components/Shared/CustomLabel"
import CustomField from "components/Shared/CustomField"
import FormButtons from "components/Shared/FormButtons"
import {
  fileAccept,
  handleFileUpload,
  handleSquareFileUpload,
} from "components/Common/Utils"
import FileUpload from "components/Shared/FileUpload"
import CustomErrorMessage from "components/Shared/CustomErrorMessage"
import { createUnitSchema } from "components/Common/validation"
import TrashIcon from "assets/images/icons/trash.svg"
import { useDispatch, useSelector } from "react-redux"
import IsLoading from "components/Shared/IsLoading"
import {
  unitAreaOptions,
  unitStatusOptions,
  unitTypeOptions,
} from "components/Common/constants"
import { getRoomTypesList } from "store/actions/roomTypesAction"
import { getProjectsList } from "store/actions/projectsAction"
import { updateUnit } from "store/actions/unitsAction"
import { showAlert } from "store/reducers/alertReducer"
import { unitUploadMaxSize } from "components/Common/FileUploadSizeUtils"

const initialValues = {
  name: "",
  nameAr: "",
  project: "",
  description: "",
  descriptionAr: "",
  address: "",
  addressAr: "",
  // latitude: "",
  // longitude: "",
  unitType: "",
  area: "",
  areaUnit: "",
  doorPassword: "",
  image: "",
  unitPrice: "",
  realEstateTax: "",
  // pursuit: "",
  // questTax: "",
  statusType: "",
  // isListedForRent: true,
  roomDetails: [{ roomType: "", count: "" }],
  deedNumber: "",
  document: "",
}

const GeneralDetails = ({ mode, id }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const [formValues, setFormValues] = useState(initialValues)
  const dispatch = useDispatch()
  const [isLoaded, setIsLoaded] = useState(false)
  const { isLoading, unitDetails } = useSelector(state => state.Units)
  const { roomTypesList } = useSelector(state => state.RoomTypes)
  const { projectsList } = useSelector(state => state.Projects)

  useEffect(() => {
    if (unitDetails) {
      const {
        statusType,
        status,
        projectId,
        name,
        nameAr,
        description,
        descriptionAr,
        imageUrl,
        address,
        addressAr,
        // latitude,
        // longitude,
        roomDetails,
        deedDetails,
        // isListedForRent,
      } = unitDetails

      const { unitType, area, areaUnit, doorPassword } =
        unitDetails?.generalDetails || {}
      const {
        unitPrice,
        realEstateTax,
        // persuit,
        // questTax,
        totalPrice,
        currency,
      } = unitDetails?.priceDetails || {}

      const { deedNumber, document } = unitDetails?.deedDetails || {}

      const updatedRoomDetails =
        roomDetails?.map(item => ({
          roomType: item?.roomType?._id,
          count: item?.count,
        })) || []

      setFormValues(prev => ({
        ...prev,
        name,
        nameAr,
        project: projectId?._id,
        unitType,
        statusType: status,
        area,
        areaUnit,
        doorPassword,
        image: imageUrl,
        address,
        addressAr,
        // latitude,
        // longitude,
        unitPrice,
        realEstateTax,
        // pursuit: persuit,
        // questTax,
        description,
        descriptionAr,
        // isListedForRent,
        roomDetails: updatedRoomDetails,
        deedNumber,
        document,
      }))
      setIsLoaded(true)
    }
  }, [language, unitDetails])

  useEffect(() => {
    fetchRoomTypesList()
    fetchProjectsList()
  }, [])

  const fetchRoomTypesList = () => {
    dispatch(getRoomTypesList())
  }
  const fetchProjectsList = () => {
    dispatch(getProjectsList())
  }

  const handleOnSubmit = values => {
    const {
      name,
      nameAr,
      project,
      description,
      descriptionAr,
      address,
      addressAr,
      // latitude,
      // longitude,
      unitType,
      area,
      areaUnit,
      doorPassword,
      image,
      unitPrice,
      realEstateTax,
      // pursuit,
      // questTax,
      statusType,
      // isListedForRent,
      roomDetails,
      document,
      deedNumber,
    } = values

    let formData = new FormData()
    formData.append("id", id)
    formData.append("name", name)
    formData.append("nameAr", nameAr)
    formData.append("description", description)
    formData.append("descriptionAr", descriptionAr)
    formData.append("address", address)
    formData.append("addressAr", addressAr)
    // formData.append("latitude", latitude)
    // formData.append("longitude", longitude)
    formData.append("generalDetails[unitType]", unitType)
    formData.append("generalDetails[area]", area)
    formData.append("generalDetails[areaUnit]", areaUnit)
    formData.append("generalDetails[doorPassword]", doorPassword)
    formData.append("priceDetails[unitPrice]", unitPrice)
    formData.append("priceDetails[realEstateTax]", realEstateTax)
    // formData.append("priceDetails[persuit]", pursuit)
    // formData.append("priceDetails[questTax]", questTax)
    roomDetails?.forEach((item, index) => {
      formData.append(`roomDetails[${index}][roomType]`, item.roomType)
      formData.append(`roomDetails[${index}][count]`, item.count)
    })

    formData.append("statusType", statusType)
    formData.append("projectId", project)
    // formData.append("isListedForRent", isListedForRent)
    formData.append("image", image)
    // formData.append("deedNumber", deedNumber)
    // formData.append("document", document)

    dispatch(updateUnit(formData))
      .then(action => {
        const { statusCode, success, message, data } = action?.payload
        if (statusCode === 200 && success) {
          dispatch(showAlert({ text: message, type: "success" }))
        } else {
          dispatch(showAlert({ text: message, type: "error" }))
        }
      })
      .catch(error => {})
  }

  return (
    <div>
      {!isLoaded ? (
        <IsLoading />
      ) : (
        <Formik
          initialValues={formValues}
          validationSchema={createUnitSchema(mode)}
          onSubmit={handleOnSubmit}
        >
          {({ values, errors, touched, setFieldValue }) => (
            <Form>
              {" "}
              <Row>
                {/* <Col lg={6}>
                  <div className="form_field">
                    <CustomLabel
                      htmlFor="name"
                      label={t("Name(English)")}
                      isRequired={true}
                    />
                    <CustomField
                      name="name"
                      type="text"
                      placeholder={t("Name(English)")}
                      touched={touched}
                      errors={errors}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form_field">
                    <CustomLabel
                      htmlFor="nameAr"
                      label={t("Name(Arabic)")}
                      isRequired={true}
                    />
                    <CustomField
                      name="nameAr"
                      type="text"
                      placeholder={t("Name(Arabic)")}
                      touched={touched}
                      errors={errors}
                      isArabic={true}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form_field">
                    <CustomLabel
                      htmlFor="project"
                      label={t("Project")}
                      isRequired={true}
                    />
                    <div className="select_wrapper">
                      <Field
                        as="select"
                        name="project"
                        disabled={true}
                        className={`form-control b_r_12 ${
                          errors.project && touched.project ? "is-invalid" : ""
                        }`}
                        onChange={e => {
                          const selectedValue = e.target.value
                          setFieldValue("project", selectedValue)
                        }}
                      >
                        <option hidden>{t("Project")}</option>
                        {projectsList?.map(item => (
                          <option key={item._id} value={item._id}>
                            {language === "sa" ? item?.nameAr : item?.name}
                          </option>
                        ))}
                      </Field>
                    </div>
                    <CustomErrorMessage name={"project"} />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form_field">
                    <CustomLabel
                      htmlFor="unitType"
                      label={t("Unit Type")}
                      isRequired={true}
                    />
                    <div className="select_wrapper">
                      <Field
                        as="select"
                        name="unitType"
                        className={`form-control b_r_12 ${
                          errors.unitType && touched.unitType
                            ? "is-invalid"
                            : ""
                        }`}
                        onChange={e => {
                          const selectedValue = e.target.value
                          setFieldValue("unitType", selectedValue)
                        }}
                      >
                        <option hidden>{t("Unit Type")}</option>
                        {unitTypeOptions?.map(item => (
                          <option key={item.value} value={item.value}>
                            {t(item.label)}
                          </option>
                        ))}
                      </Field>
                    </div>
                    <CustomErrorMessage name={"unitType"} />
                  </div>
                </Col> */}
                {/* <Col lg={6}>
                  <div className="form_field">
                    <CustomLabel
                      htmlFor="statusType"
                      label={t("Unit Status")}
                      isRequired={true}
                    />
                    <div className="select_wrapper">
                      <Field
                        as="select"
                        name="statusType"
                        className={`form-control b_r_12 ${
                          errors.statusType && touched.statusType
                            ? "is-invalid"
                            : ""
                        }`}
                        onChange={e => {
                          const selectedValue = e.target.value
                          setFieldValue("statusType", selectedValue)
                        }}
                      >
                        <option hidden>{t("Unit Status")}</option>
                        {unitStatusOptions?.map(item => (
                          <option key={item.value} value={item.value}>
                            {t(item.label)}
                          </option>
                        ))}
                      </Field>
                    </div>
                    <CustomErrorMessage name={"statusType"} />
                  </div>
                </Col> */}
                {/* <Col lg={6}>
                  <div className="form_field">
                    <CustomLabel
                      htmlFor="area"
                      label={t("Area")}
                      isRequired={true}
                    />
                    <CustomField
                      name="area"
                      type="text"
                      placeholder={t("Area")}
                      touched={touched}
                      errors={errors}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form_field">
                    <CustomLabel
                      htmlFor="areaUnit"
                      label={t("Area Unit")}
                      isRequired={true}
                    />
                    <div className="select_wrapper">
                      <Field
                        as="select"
                        name="areaUnit"
                        className={`form-control b_r_12 ${
                          errors.areaUnit && touched.areaUnit
                            ? "is-invalid"
                            : ""
                        }`}
                        onChange={e => {
                          const selectedValue = e.target.value
                          setFieldValue("areaUnit", selectedValue)
                        }}
                      >
                        <option hidden>{t("Area Unit")}</option>
                        {unitAreaOptions?.map(item => (
                          <option key={item.value} value={item.value}>
                            {t(item.label)}
                          </option>
                        ))}
                      </Field>
                    </div>
                    <CustomErrorMessage name={"areaUnit"} />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form_field">
                    <CustomLabel
                      htmlFor="doorPassword"
                      label={t("Door Password")}
                      isRequired={true}
                    />
                    <CustomField
                      name="doorPassword"
                      type="text"
                      placeholder={t("Door Password")}
                      touched={touched}
                      errors={errors}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form_field">
                    <CustomLabel
                      htmlFor="unitPrice"
                      label={t("Unit Price")}
                      isRequired={true}
                    />
                    <CustomField
                      name="unitPrice"
                      type="text"
                      placeholder={t("Unit Price")}
                      touched={touched}
                      errors={errors}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form_field">
                    <CustomLabel
                      htmlFor="realEstateTax"
                      label={t("Real Estate Tax")}
                      isRequired={true}
                    />
                    <CustomField
                      name="realEstateTax"
                      type="text"
                      placeholder={t("Real Estate Tax")}
                      touched={touched}
                      errors={errors}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form_field">
                    <CustomLabel
                      htmlFor="pursuit"
                      label={t("Pursuit")}
                      isRequired={true}
                    />
                    <CustomField
                      name="pursuit"
                      type="text"
                      placeholder={t("Pursuit")}
                      touched={touched}
                      errors={errors}
                    />
                  </div>
                </Col> */}
                {/* <Col lg={6}>
                  <div className="form_field">
                    <CustomLabel
                      htmlFor="questTax"
                      label={t("Quest Tax")}
                      isRequired={true}
                    />
                    <CustomField
                      name="questTax"
                      type="text"
                      placeholder={t("Quest Tax")}
                      touched={touched}
                      errors={errors}
                    />
                  </div>
                </Col> */}
                {/* <Col lg={6}>
                  <div className="form_field">
                    <CustomLabel
                      htmlFor="address"
                      label={t("Address(English)")}
                      isRequired={true}
                    />
                    <CustomField
                      name="address"
                      type="text"
                      placeholder={t("Address(English)")}
                      touched={touched}
                      errors={errors}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form_field">
                    <CustomLabel
                      htmlFor="addressAr"
                      label={t("Address(Arabic)")}
                      isRequired={true}
                    />
                    <CustomField
                      name="addressAr"
                      type="text"
                      isArabic={true}
                      placeholder={t("Address(Arabic)")}
                      touched={touched}
                      errors={errors}
                    />
                  </div>
                </Col> */}
                {/* <Col lg={6}>
                  <div className="form_field">
                    <CustomLabel
                      htmlFor="latitude"
                      label={t("Latitude")}
                      isRequired={true}
                    />
                    <CustomField
                      name="latitude"
                      type="text"
                      placeholder={t("Latitude")}
                      touched={touched}
                      errors={errors}
                    />
                  </div>
                </Col> */}
                {/* <Col lg={6}>
                  <div className="form_field">
                    <CustomLabel
                      htmlFor="longitude"
                      label={t("Longitude")}
                      isRequired={true}
                    />
                    <CustomField
                      name="longitude"
                      type="text"
                      placeholder={t("Longitude")}
                      touched={touched}
                      errors={errors}
                    />
                  </div>
                </Col> */}
                {/* <Col lg={6}></Col> */}

                {/* <Col lg={6}>
                  <div className="mt-3 g_label">
                    <CustomLabel
                      htmlFor="isListedForRent"
                      label={t("Is For Rent")}
                    />

                    <div className="d-flex ">
                      <div className="form-check mx-2">
                        <Field
                          className="form-check-input"
                          type="radio"
                          name="isListedForRent"
                          id="rent"
                          value="rent"
                          checked={values.isListedForRent === true}
                          onClick={() => {
                            setFieldValue("isListedForRent", true)
                          }}
                        />
                        <label className="form-check-label" htmlFor="rent">
                          {t("For Rent")}
                        </label>
                      </div>
                      <div className="form-check mx-2">
                        <Field
                          className="form-check-input"
                          type="radio"
                          name="isListedForRent"
                          id="sell"
                          value="sell"
                          checked={values.isListedForRent === false}
                          onClick={() => {
                            setFieldValue("isListedForRent", false)
                          }}
                        />
                        <label className="form-check-label" htmlFor="sell">
                          {t("For Sale")}
                        </label>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={6}></Col> */}
                {/* <Col lg={6}>
                  <div className="form_field">
                    <CustomLabel
                      htmlFor="description"
                      label={t("Description(English)")}
                      isRequired={true}
                    />
                    <Field
                      as="textarea"
                      id="description"
                      name="description"
                      rows={5}
                      placeholder={t("Description(English)")}
                      className={`form-control b_r_12 ${
                        errors.description && touched.description
                          ? "is-invalid"
                          : ""
                      }`}
                    />
                    <CustomErrorMessage name={"description"} />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="form_field">
                    <CustomLabel
                      htmlFor="descriptionAr"
                      label={t("Description(Arabic)")}
                      isRequired={true}
                    />
                    <Field
                      as="textarea"
                      id="descriptionAr"
                      name="descriptionAr"
                      rows={5}
                      placeholder={t("Description(Arabic)")}
                      className={`form-control b_r_12 ${
                        errors.descriptionAr && touched.descriptionAr
                          ? "is-invalid"
                          : ""
                      }`}
                    />
                    <CustomErrorMessage name={"descriptionAr"} />
                  </div>
                </Col>
                <FieldArray name="roomDetails">
                  {({ remove, push }) => (
                    <div>
                      <div className="g_between mt-2">
                        <h2 className="fs_18_700 ">
                          {t("Add Amenities Details")}
                        </h2>
                        <button
                          className="cancel_btn"
                          type="button"
                          onClick={() =>
                            push({
                              roomType: "",
                              count: "",
                            })
                          }
                        >
                          {t("Add More")}
                        </button>
                      </div>
                      {values?.roomDetails?.map((field, index) => (
                        <div key={index} className="primary_bg_10">
                          <Row>
                            <Col lg={6}>
                              <div className="form_field">
                                <CustomLabel
                                  htmlFor={`roomDetails.${index}.roomType`}
                                  label={t("Amenities")}
                                  isRequired={true}
                                />
                                <div className="select_wrapper">
                                  <Field
                                    as="select"
                                    name={`roomDetails.${index}.roomType`}
                                    id={`roomDetails.${index}.roomType`}
                                    className={`form-control  ${
                                      errors.roomDetails?.[index]?.roomType &&
                                      touched.roomDetails?.[index]?.roomType
                                        ? "is-invalid"
                                        : ""
                                    } `}
                                    onChange={e => {
                                      const selectedValue = e.target.value
                                      setFieldValue(
                                        `roomDetails.${index}.roomType`,
                                        selectedValue
                                      )
                                    }}
                                  >
                                    <option hidden>{t("Amenities")}</option>
                                    {roomTypesList?.map(item => (
                                      <option key={item._id} value={item._id}>
                                        {language === "sa"
                                          ? item.nameAr
                                          : item.name}
                                      </option>
                                    ))}
                                  </Field>
                                </div>
                                <CustomErrorMessage
                                  name={`roomDetails.${index}.roomType`}
                                />
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="form_field">
                                <CustomLabel
                                  htmlFor={`roomDetails.${index}.count`}
                                  label={t("Number Of Count")}
                                  isRequired={true}
                                />
                                <div className="d-flex align-items-center">
                                  <Field
                                    name={`roomDetails.${index}.count`}
                                    type={"number"}
                                    id={`roomDetails.${index}.count`}
                                    placeholder={t("Count")}
                                    className={`form-control  ${
                                      errors.roomDetails?.[index]?.count &&
                                      touched.roomDetails?.[index]?.count
                                        ? "is-invalid"
                                        : ""
                                    } `}
                                  />
                                  {values.roomDetails.length > 1 && (
                                    <img
                                      src={TrashIcon}
                                      alt="Trash Icon"
                                      width={24}
                                      height={24}
                                      className="cursor-pointer mx-3"
                                      onClick={() => remove(index)}
                                    />
                                  )}
                                </div>
                                <CustomErrorMessage
                                  name={`roomDetails.${index}.count`}
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      ))}
                    </div>
                  )}
                </FieldArray> */}

                {/* <Col lg={6}>
                  <div className="form_field">
                    <CustomLabel
                      htmlFor="deedNumber"
                      label={t("Deed Number")}
                      isRequired={true}
                    />
                    <CustomField
                      name="deedNumber"
                      type="text"
                      placeholder={t("Deed Number")}
                      touched={touched}
                      errors={errors}
                    />
                  </div>
                </Col> */}
                {/* <Col lg={6}>
                  <div className="form_field">
                    <CustomLabel
                      htmlFor="document"
                      label={t("Upload Deed Document")}
                      isRequired={true}
                    />
                    <FileUpload
                      name="document"
                      value={values.document}
                      touched={touched.document}
                      error={errors.document}
                      labelText={t("Upload Deed Document")}
                      setFieldValue={setFieldValue}
                      accept={fileAccept}
                      customClasses="h_46"
                      showNameOnly={true}
                      handleFileUpload={event =>
                        handleFileUpload(event, setFieldValue, "document")
                      }
                    />
                    <CustomErrorMessage name={"document"} />
                  </div>
                </Col> */}
                <Col lg={6}>
                  <div className="form_field">
                    <CustomLabel
                      htmlFor="image"
                      label={t("Upload Feature Image")}
                      isRequired={true}
                    />
                    <FileUpload
                      name="image"
                      value={values.image}
                      touched={touched.image}
                      error={errors.image}
                      labelText={t("Upload Feature Image")}
                      setFieldValue={setFieldValue}
                      handleFileUpload={event =>
                        handleSquareFileUpload(
                          event,
                          setFieldValue,
                          "image",
                          true,
                          unitUploadMaxSize
                        )
                      }
                    />
                    <CustomErrorMessage name={"image"} />
                    <p className="fs_14_500 opacity-75">
                      {t(
                        "Recommended Image Size: Square (e.g., 540x540 px). Max size: {{maxSize}} KB",
                        { maxSize: unitUploadMaxSize }
                      )}
                    </p>
                  </div>
                </Col>
                <Col lg={12}>
                  <FormButtons buttonText={t("Update")} isLoading={isLoading} />
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      )}
    </div>
  )
}

export default GeneralDetails
