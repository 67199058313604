import { notificationActionTypeOptions } from "components/Common/constants"
import DetailsInnerDiv from "components/Shared/DetailsInnerDiv"
import DetailsTitle from "components/Shared/DetailsTitle"
import FormateDate from "components/Shared/FormateDate"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import TopTitle from "components/Shared/TopTitle"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { ShimmerThumbnail } from "react-shimmer-effects"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import { getAdminNotificationDetails } from "store/actions/adminNotificationAction"
import { getNotificationDetails } from "store/actions/notificationsActions"

const NotificationDetails = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("Notification Details | Takween")

  const dispatch = useDispatch()
  const { loading, adminNotificationDetails } = useSelector(
    state => state.AdminNotifications
  )

  const { userType } = useSelector(state => state.Login)

  const name = useSelector(state => state.Login.userProfile?.name) || ""

  const { search } = useLocation()
  const id = new URLSearchParams(search).get("id")

  useEffect(() => {
    const fetchDetails = () => {
      const payload = {
        id,
      }
      dispatch(getAdminNotificationDetails(payload))
    }
    fetchDetails()
  }, [id])

  return (
    <React.Fragment>
      <div className="page-content customer_details">
        <Container fluid>
          <TopTitle
            title={t("Notification Details")}
            subTitle={t(`Hi, {{name}}. Welcome back to your {{type}} portal!`, {
              name: name,
              type: t(userType),
            })}
          />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <DetailsTitle title={t("Notification Details")} />

                  <div>
                    {loading ? (
                      <ShimmerThumbnail
                        height={350}
                        rounded
                        className={"mb-0"}
                      />
                    ) : Object.values(adminNotificationDetails).length === 0 ? (
                      <p className="text-center my-3">
                        <NoDataAvailable />
                      </p>
                    ) : (
                      <>
                        <Row className="g_details">
                          <Col md={4}>
                            <DetailsInnerDiv
                              label={t("Id")}
                              value={adminNotificationDetails._id}
                            />
                          </Col>
                          <Col md={4}>
                            <DetailsInnerDiv
                              label={t("Created At")}
                              value={FormateDate(
                                adminNotificationDetails?.createdAt
                              )}
                            />
                          </Col>
                          {userType === "superadmin" ? (
                            <>
                              <Col md={4}>
                                <DetailsInnerDiv
                                  label={t("Request Number")}
                                  value={
                                    adminNotificationDetails?.data?.requestNo ??
                                    "-"
                                  }
                                />
                              </Col>
                              <Col md={4}>
                                <DetailsInnerDiv
                                  label={t("Service Request Id")}
                                  value={
                                    adminNotificationDetails?.data
                                      ?.serviceRequestId ?? "-"
                                  }
                                />
                              </Col>
                            </>
                          ) : (
                            <Col md={4}>
                              <DetailsInnerDiv
                                label={t("Booking Id")}
                                value={
                                  adminNotificationDetails?.data?.bookingId ??
                                  "-"
                                }
                              />
                            </Col>
                          )}
                          {/* <Col md={4}>
                            <DetailsInnerDiv
                              label={t("Updated At")}
                              value={FormateDate(
                                adminNotificationDetails?.updatedAt
                              )}
                            />
                          </Col> */}
                          <Col md={4}>
                            <DetailsInnerDiv
                              label={t("Customer Name")}
                              value={
                                adminNotificationDetails?.userId?.name ?? "-"
                              }
                            />
                          </Col>
                          <Col md={4}>
                            <DetailsInnerDiv
                              label={t("Customer Email")}
                              value={
                                adminNotificationDetails?.userId?.email ?? "-"
                              }
                            />
                          </Col>
                          <Col md={4}>
                            <DetailsInnerDiv
                              label={t("Customer Phone")}
                              value={
                                adminNotificationDetails?.userId?.phone ?? "-"
                              }
                            />
                          </Col>
                          <Col md={4}>
                            <DetailsInnerDiv
                              label={t("Project Name")}
                              value={
                                language === "sa"
                                  ? adminNotificationDetails?.projectId?.nameAr
                                  : adminNotificationDetails?.projectId?.name ??
                                    "-"
                              }
                            />
                          </Col>
                          <Col md={4}>
                            <DetailsInnerDiv
                              label={t("Unit Name")}
                              value={
                                language === "sa"
                                  ? adminNotificationDetails?.unitId?.nameAr
                                  : adminNotificationDetails?.unitId?.name ??
                                    "-"
                              }
                            />
                          </Col>
                          <Col md={12}>
                            <DetailsInnerDiv
                              label={t("Title")}
                              value={
                                language === "sa"
                                  ? adminNotificationDetails?.title?.ar
                                  : adminNotificationDetails?.title?.en || "-"
                              }
                            />
                          </Col>
                          <Col md={12}>
                            <DetailsInnerDiv
                              label={t("Description")}
                              value={
                                language === "sa"
                                  ? adminNotificationDetails?.body?.ar
                                  : adminNotificationDetails?.body?.en || "-"
                              }
                            />
                          </Col>
                        </Row>
                      </>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default NotificationDetails
