import React, { useEffect } from "react"
import { Table } from "reactstrap"
import { FormateOnlyDate } from "components/Shared/FormateDate"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import { getAllServiceRequest } from "store/actions/maintenanceUsersAction"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import {
  serviceRequestStatus,
  serviceRequestType,
} from "components/Common/constants"
import Paginations from "components/Common/Pagination"

const AssignedSlots = ({ t, id, maintenanceUserDetails }) => {
  const {
    i18n: { language },
  } = useTranslation()

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { search } = useLocation()
  const pageNumber = Number(new URLSearchParams(search).get("page")) || 1

  const {
    loading,
    isLoading,
    currentPage,
    totalPages,
    totalCount,
    maintenanceServiceRequest,

    perPage,
  } = useSelector(state => state.MaintenanceUsers)

  useEffect(() => {
    fetchAllServiceRequest()
  }, [])

  const fetchAllServiceRequest = page => {
    const payload = {
      perPage: perPage,
      pageNo: page || pageNumber,
      status: -1,
      orderBy: 2,
      service: [],
      keyword: "",
      dateRange: {},
      unit: [],
      customer: [],
      serviceItem: [],
      serviceProvider: [id],
      dateRangeScheduled: {},
    }
    dispatch(getAllServiceRequest(payload))
  }

  const onPageChange = page => {
    fetchAllServiceRequest(page)
  }

  return (
    <div>
      {maintenanceServiceRequest?.length === 0 ? (
        <NoDataAvailable />
      ) : (
        <div className="table-responsive">
          <Table className="table mb-0">
            <thead className="full_th">
              <tr>
                <th>{t("#")}</th>
                <th className="px-0">{t("Request Number")}</th>
                <th className="px-0">{t("Customer Name")}</th>
                <th className="px-0">{t("Customer Phone Number")}</th>
                <th className="px-0">{t("Service Name")}</th>
                <th className="px-0">{t("Request Type")}</th>
                <th className="px-0">{t("Schedule Date")}</th>
                <th className="px-0">{t("Schedule Time")}</th>
                <th className="px-0">{t("Status")}</th>
              </tr>
            </thead>
            {maintenanceServiceRequest?.map((item, index) => (
              <tr key={item._id}>
                <th scope="row" className="py-3">
                  {index + 1}
                </th>
                <td>{item?.requestNo}</td>
                <td>{item?.userData?.name || "-"}</td>
                <td>{item?.userData?.phone || "-"}</td>
                <td>
                  {language === "sa"
                    ? item?.serviceData?.nameAr
                    : item?.serviceData?.name}
                </td>
                <td>
                  {t(
                    serviceRequestType.find(
                      type => type.value === item.requestType
                    )?.label || "-"
                  )}
                </td>
                <td>{FormateOnlyDate(item.scheduledDate)}</td>
                <td>{item.scheduledTime || "-"}</td>
                <td>
                  {serviceRequestStatus.find(
                    status => status.value === item.status
                  )?.label || "-"}
                </td>
              </tr>
            ))}
          </Table>
          {!loading && maintenanceServiceRequest.length > 0 && (
            <div className="p-3">
              <Paginations
                currentPage={currentPage}
                totalPages={totalPages}
                totalCount={totalCount}
                onPageChange={onPageChange}
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default AssignedSlots
