import React, { useEffect } from "react"
import PDFIcon from "assets/images/icons/pdf.svg"
import PNGIcon from "assets/images/icons/PNG.svg"
import SVGIcon from "assets/images/icons/SVG.svg"
import HEIFIcon from "assets/images/icons/HEIF.svg"
import JPEGIcon from "assets/images/icons/JPG.svg"
import XLSXIcon from "assets/images/icons/XLS.svg"
import CSVIcon from "assets/images/icons/CSV.svg"
import DefaultIcon from "assets/images/icons/default.svg"
import { Link } from "react-router-dom"

const fileIcons = {
  pdf: PDFIcon,
  svg: SVGIcon,
  heif: HEIFIcon,
  png: PNGIcon,
  jpeg: JPEGIcon,
  jpg: JPEGIcon,
  xlsx: XLSXIcon,
  csv: CSVIcon,
  default: DefaultIcon,
}

const DetailsInnerDiv = ({
  label = "",
  value = "",
  valueClass = "",
  isPdfIcon = false,
  isLink = false,
  redirectLink = "#",
}) => {
  // Extract file type from redirectLink
  const getFileExtension = url => {
    const match = url?.match(/\.(\w+)(?:\?.*)?$/)
    return match ? match[1].toLowerCase() : null
  }

  const fileExtension = getFileExtension(redirectLink)
  const icon = fileIcons[fileExtension] || fileIcons.default

  return (
    <div className="details_inner_div">
      <p>{label}</p>
      <h2 className={valueClass}>
        {isLink ? (
          redirectLink ? (
            <Link to={redirectLink} target="_blank">
              {isPdfIcon && (
                <img
                  src={icon}
                  alt={fileExtension || "file"}
                  style={{
                    position: "relative",
                    top: "-2px",
                    marginRight: 5,
                  }}
                />
              )}
              {value}
            </Link>
          ) : (
            "-"
          )
        ) : (
          value
        )}
      </h2>
    </div>
  )
}

export default DetailsInnerDiv
