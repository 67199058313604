import React from "react"
import { Spinner } from "reactstrap"

const TopTitle = ({
  title,
  subTitle,
  createBtnText = null,
  onCreateBtnClick = null,
  secondaryBtnText = null,
  onSecondaryBtnClick = () => {},
  isLoading = false,
}) => {
  return (
    <div className="g_between">
      <div>
        <h1 className="main_title">{title}</h1>
        {subTitle && <p className="sub_title">{subTitle}</p>}
      </div>
      <div className="d-flex gap-3">
        {secondaryBtnText && (
          <button
            className="cancel_btn g_center gap-2"
            style={{ minWidth: "fit-content" }}
            disabled={isLoading}
            onClick={onSecondaryBtnClick}
          >
            {secondaryBtnText}
            {isLoading && (
              <Spinner color="primary" style={{ width: 30, height: 30 }} />
            )}
          </button>
        )}
        {createBtnText && (
          <button className="create_btn" onClick={onCreateBtnClick}>
            {createBtnText}
          </button>
        )}
      </div>
    </div>
  )
}

export default TopTitle
