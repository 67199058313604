import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  Row,
  TabContent,
  Table,
  NavLink,
  TabPane,
  CarouselItem,
  CarouselCaption,
  Carousel,
  CarouselIndicators,
  CarouselControl,
} from "reactstrap"
import { useTranslation } from "react-i18next"
import DetailsTitle from "components/Shared/DetailsTitle"
import NoDataAvailable from "components/Shared/NoDataAvailable"
import classNames from "classnames"
import DetailsInnerDiv from "components/Shared/DetailsInnerDiv"
import { useDispatch, useSelector } from "react-redux"
import { Link, useSearchParams } from "react-router-dom"
import { getUnitDetails } from "store/actions/unitsAction"
import { ShimmerThumbnail } from "react-shimmer-effects"
import LocationIcon from "assets/images/icons/location.svg"
import { getUnitStatus } from "components/Common/StatusUtils"

const UnitDetails = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()
  document.title = t("Unit Details | Takween")

  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const id = searchParams.get("id")

  const { loading, isLoading, unitDetails } = useSelector(state => state.Units)

  useEffect(() => {
    const fetchDetails = () => {
      const payload = {
        id,
      }
      dispatch(getUnitDetails(payload))
    }
    fetchDetails()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content unit_details g_details">
        <Container fluid>
          {loading ? (
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <ShimmerThumbnail height={400} rounded className={"mb-0"} />
                  </CardBody>
                </Card>
              </Col>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    <ShimmerThumbnail height={400} rounded className={"mb-0"} />
                  </CardBody>
                </Card>
              </Col>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    <ShimmerThumbnail height={400} rounded className={"mb-0"} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : Object.values(unitDetails).length === 0 ? (
            <p className="text-center my-3">
              <NoDataAvailable />
            </p>
          ) : (
            <Row>
              <Col md={12}>
                <Card>
                  <CardBody>
                    <DetailsTitle title={t("Unit Details")} />
                    <Row className="mt-4">
                      <Col md={4}>
                        <div>
                          <img
                            src={unitDetails.imageUrl}
                            alt={unitDetails.name}
                            className="img-fluid rounded-4 w-100"
                          />
                        </div>
                      </Col>
                      <Col md={8}>
                        <Row className="border-bottom pb-3">
                          <Col lg={8}>
                            <h2 className="main_title">
                              {language === "sa"
                                ? unitDetails.nameAr
                                : unitDetails.name}
                            </h2>
                            <p className="fs_18_400 d-flex text_black">
                              <img
                                src={LocationIcon}
                                alt="location icon"
                                className="me-1"
                                width={24}
                                height={24}
                              />
                              {language === "sa"
                                ? unitDetails.addressAr
                                : unitDetails.address}
                            </p>
                          </Col>
                          <Col md={4}>
                            <DetailsInnerDiv
                              label={t("Status Type")}
                              value={t(getUnitStatus(unitDetails.status).label)}
                              valueClass={`${
                                getUnitStatus(unitDetails.status).className
                              } status_tag fit_content`}
                            />
                          </Col>

                          <Col md={4}>
                            <DetailsInnerDiv
                              label={t("Project Name")}
                              value={
                                language === "sa"
                                  ? unitDetails?.projectId?.nameAr
                                  : unitDetails?.projectId?.name
                              }
                            />
                          </Col>
                          <Col md={4}>
                            <DetailsInnerDiv
                              label={t("Unit Type")}
                              value={t(
                                unitDetails?.generalDetails?.unitType || "-"
                              )}
                            />
                          </Col>
                          <Col md={4}>
                            <DetailsInnerDiv
                              label={t("Area")}
                              value={unitDetails?.generalDetails?.area ?? "-"}
                            />
                          </Col>
                          <Col md={4}>
                            <DetailsInnerDiv
                              label={t("Area Unit")}
                              value={t(
                                unitDetails?.generalDetails?.areaUnit || "-"
                              )}
                            />
                          </Col>
                          <Col md={4}>
                            <DetailsInnerDiv
                              label={t("Unit Price")}
                              value={
                                unitDetails?.priceDetails?.unitPrice ?? "-"
                              }
                            />
                          </Col>
                          <Col md={4}>
                            <DetailsInnerDiv
                              label={t("Real Estate Tax")}
                              value={
                                unitDetails?.priceDetails?.realEstateTax ?? "-"
                              }
                            />
                          </Col>
                          <Col md={4}>
                            <DetailsInnerDiv
                              label={t("Persuit")}
                              value={unitDetails?.priceDetails?.persuit ?? "-"}
                            />
                          </Col>
                          <Col md={12}>
                            <DetailsInnerDiv
                              label={t("General Details")}
                              value={unitDetails.description}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Row>
                {/* <Col lg={4}>
                <Card className="nearby_landmarks full_height_card">
                  <CardBody>
                    <h2 className="fs_20 font-bold text_black">
                      {t("Nearby Landmarks")}
                    </h2>
                    <ul>
                      {unitDetails?.nearByLandMarks?.length === 0 ||
                      !unitDetails?.nearByLandMarks ? (
                        <p className="fs_16 text-center py-4">
                          {t("No Near By Landmarks Added")}
                        </p>
                      ) : (
                        unitDetails?.nearByLandMarks?.map((item, index) => (
                          <div key={index}>
                            <li>
                              <span className="fs_16 fw_400">{item.name}</span>
                              <span>
                                {item.distance} {item.distanceUnit}
                              </span>
                            </li>
                            {index !==
                              unitDetails?.nearbyLandMarks?.length - 1 && (
                              <hr className="hr_100" />
                            )}
                          </div>
                        ))
                      )}
                    </ul>
                  </CardBody>
                </Card>
              </Col> */}
                <Col lg={6}>
                  <Card className="facility_row full_height_card">
                    <CardBody>
                      <h2 className="fs_20 font-bold text_black">
                        {t("Facilities & Services")}
                      </h2>
                      <Row>
                        {unitDetails?.facilitiesAndServices?.length === 0 ? (
                          <p className="fs_16 text-center py-4">
                            {t("No Facilities & Services Added")}
                          </p>
                        ) : (
                          <ul>
                            {unitDetails?.facilitiesAndServices?.map(
                              (facility, index) => (
                                <Col lg={4} key={index}>
                                  <li>
                                    {facility.imageUrl && (
                                      <img
                                        width={24}
                                        height={24}
                                        src={facility.imageUrl}
                                        // alt="icon"
                                      />
                                    )}
                                    <span>
                                      {language === "sa"
                                        ? facility?.nameAr
                                        : facility?.name || "-"}
                                    </span>
                                  </li>
                                  {/* <p>
                                  {language === "sa"
                                    ? facility?.descriptionAr
                                    : facility?.description || "-"}
                                </p> */}
                                  {/* <ul className="ps-0">
                                {facility?.lists?.length === 0
                                  ? ""
                                  : facility?.lists?.map(list => (
                                    <li key={list._id}>
                                        <img
                                          width={24}
                                          height={24}
                                          src={list.imageUrl}
                                          alt="icon"
                                        />
                                        <span>
                                        {language === "sa"
                                        ? list.nameAr
                                            : list.name}
                                            </span>
                                      </li>
                                    ))}
                              </ul> */}
                                </Col>
                              )
                            )}
                          </ul>
                        )}
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={6}>
                  <Card className="nearby_landmarks full_height_card">
                    <CardBody>
                      <h2 className="fs_20 font-bold text_black">
                        {t("Features (Guarantees)")}
                      </h2>
                      <ul>
                        {unitDetails?.guarantees?.length === 0 ? (
                          <p className="fs_16 text-center py-4">
                            {t("No Guarantees Added")}
                          </p>
                        ) : (
                          unitDetails?.guarantees?.map((item, index) => (
                            <div key={index}>
                              <li>
                                <span className="fs_16 fw_400">
                                  {language === "sa"
                                    ? item?.serviceId?.nameAr
                                    : item?.serviceId?.name}
                                </span>
                                <span>
                                  {item?.duration} {t(item?.durationUnit)}
                                </span>
                              </li>
                              {index !==
                                unitDetails?.nearbyLandMarks?.length - 1 && (
                                <hr className="hr_100" />
                              )}
                            </div>
                          ))
                        )}
                      </ul>
                    </CardBody>
                  </Card>
                </Col>
                {/* <Col lg={6}>
                <Card className="nearby_landmarks full_height_card">
                  <CardBody>
                    <h2 className="fs_20 font-bold text_black">
                      {t("Features (After Sales And Services)")}
                    </h2>
                    <ul>
                      {unitDetails?.afterSaleServices?.length === 0 ? (
                        <p className="fs_16 text-center py-4">
                          {t("No After Sales Services Added")}
                        </p>
                      ) : (
                        unitDetails?.afterSaleServices?.map((item, index) => (
                          <div key={index}>
                            <li>
                              <span className="fs_16 fw_400">
                                {language === "sa" ? item?.nameAr : item?.name}
                              </span>
                            </li>
                            {index !==
                              unitDetails?.nearbyLandMarks?.length - 1 && (
                              <hr className="hr_100" />
                            )}
                          </div>
                        ))
                      )}
                    </ul>
                  </CardBody>
                </Card>
              </Col> */}
                <Col lg={12}>
                  <Card className="gallery_images_row">
                    <CardBody>
                      <h2 className="fs_20 font-bold text_black">
                        {t("Gallery Images")}
                      </h2>
                      <Row>
                        {unitDetails?.galleryImages?.length === 0 ? (
                          <p className="fs_16 text-center pb-4">
                            {t("No Gallery Images Uploaded")}
                          </p>
                        ) : (
                          unitDetails?.galleryImages?.map((image, index) => (
                            <Col lg={3} key={index}>
                              <div className="single_gallery mt-4">
                                <Link to={image} target="_blank">
                                  <img
                                    src={image}
                                    className="gallery_image"
                                    alt={`gallery image ${index + 1}`}
                                  />
                                </Link>
                              </div>
                            </Col>
                          ))
                        )}
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UnitDetails
