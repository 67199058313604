import React from "react"
import { useTranslation } from "react-i18next"
import { Card, CardBody, Col, Row } from "reactstrap"
import Approvals from "assets/images/icons/finance/approvals.svg"
import RemainingPayments from "assets/images/icons/finance/RemainingPayments.svg"
import OwnerTaxInfo from "assets/images/icons/finance/ownerTaxInfo.svg"
import Complaints from "assets/images/icons/finance/complaints.svg"
import { Link } from "react-router-dom"

const FinanceTopBar = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation()

  const marketingDashboardTopBar = [
    // {
    //   id: 1,
    //   imgUrl: Approvals,
    //   text: t("Approvals"),
    //   redirectLink: "#",
    // },
    {
      id: 2,
      imgUrl: RemainingPayments,
      text: t("Remaining Payments"),
      redirectLink: "/down-payments",
    },
    {
      id: 3,
      imgUrl: OwnerTaxInfo,
      text: t("Tax Info"),
      redirectLink: "/tax-payments",
    },
    {
      id: 4,
      imgUrl: Complaints,
      text: t("Customer Support"),
      redirectLink: "/customer-support",
    },
  ]

  return (
    <Card className="dashboard_top_bar">
      <CardBody>
        <Row>
          <h2>{t("Team")}</h2>
          <p className="mt-2">{t("Finance Team")}</p>
          {marketingDashboardTopBar &&
            marketingDashboardTopBar.map(item => (
              <Col lg={3} key={item.id}>
                <Link to={item.redirectLink}>
                  <div className="inner_section">
                    <img src={item.imgUrl} alt="unit icon" />
                    <h3>{t(item.text)}</h3>
                  </div>
                </Link>
              </Col>
            ))}
        </Row>
      </CardBody>
    </Card>
  )
}

export default FinanceTopBar
